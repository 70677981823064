<template>
    <b-card title="Change Password">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        label="Email"
                        >
                        <b-form-input
                            id="admin-user-email"
                            v-model="userInfo.email"
                            type="text"
                            placeholder="Email"
                            required
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group
                        label="New Password"
                        >
                        <b-form-input
                            id="new-password"
                            v-model="newPassword"
                            type="password"
                            placeholder="New Password"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-button variant="success" @click="updatePassword">Save</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            newPassword: '',
            userInfo: {
                email: '[Fetching User Information....]',
            }
        }
    },
    mounted() {
        this.getUser(this.$route.params.userId).then((response) => {
            this.userInfo = response.data;
        }).catch((e) => {
            this.userInfo.email = 'Error....';
            alert('Failed Fetch User Information');
        })
    },
    methods: {
        ...mapActions({
            changeUserPassword: 'auth/changeUserPassword',
            getUser: 'auth/getUser'
        }),
        updatePassword() {
            this.changeUserPassword({
                id: this.userInfo.id,
                password: this.newPassword,
            }).then(async (result) => {
                if (result) {
                    this.$bvToast.toast('Change User Password Success', {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                    await sleep(1000);
                    this.$router.go(-1);
                } else {
                    this.$bvToast.toast('Failed Change Password', {
                        title: "Failed",
                        variant: "danger",
                        solid: true,
                    });
                }
            }).catch((e) => {
                console.error('Error '+e);
                this.$bvToast.toast('Change Password Error ', {
                        title: "Failed",
                        variant: "danger",
                        solid: true,
                    });
            })
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.activeUser
        }),
        disableSaveButton() {
            if (this.userInfo.id === undefined) {
                return true;
            }

            return false;
        }
    }
}
</script>
